import React from "react"
import { JesLayout } from "../components/jes-layout"
import { JesRow, JesVerticalSpacer, HalfCol } from "../components/layout"
import { Iban } from "../components/iban"
import {
  PrimaryHeading,
  SecondaryHeading,
  StandardParagraph,
  BLOCKS_SPACING,
  Legend,
  Bold,
} from "../components/blocks"
import { graphql } from "gatsby"
import { Trans } from "react-i18next"

const MitmachenPage = ({}) => {
  return (
    <JesLayout title="Mitmachen">
      <JesRow noGutter noPadding align="top">
        <JesVerticalSpacer />

        <HalfCol style={{ paddingRight: "15px" }}>
          <PrimaryHeading>
            <Trans>Unterstützen Sie uns</Trans>
          </PrimaryHeading>

          <SecondaryHeading style={{ margin: "50px 0" }}>
            <Trans i18nKey="mitmachen.first_block">
              Zur Unterstützung unseres aufwändigen Projektes suchen wir
              Spender, Paten für Künstler, Förderer und Zustifter, die durch ihr
              finanzielles Engagement dazu beitragen, die Erinnerung an die etwa
              50 einst in der Künstlerkolonie Holzhausen am Ammersee ansässigen
              Künstler zu bewahren.
            </Trans>
          </SecondaryHeading>
          <div>
            <SecondaryHeading>
              <Trans>Spender</Trans>
            </SecondaryHeading>
            <StandardParagraph>
              <Trans i18nKey="mitmachen.spender_block">
                Unterstützen Sie die JES Kulturstiftung mit einem von Ihnen
                gewählten Betrag. Bitte geben Sie bei einer Überweisung Ihre
                Adresse an, damit wir Ihnen eine Spendenbescheinigung zusenden
                können.
              </Trans>
            </StandardParagraph>
          </div>
          <div>
            <SecondaryHeading>
              <Trans>Pate</Trans>
            </SecondaryHeading>
            <StandardParagraph>
              <Trans i18nKey="mitmachen.pate_block">
                Als Pate eines von Ihnen ausgewählten Künstlers unterstützen Sie
                die Erforschung und Dokumentation dieses speziellen Künstlers
                und seiner Werke sowie die Darstellung auf der Webseite
                www.kuk.art. Sprechen Sie uns bitte wegen der Auswahl eines
                Künstlers und den konkreten Möglichkeiten und Vorteilen einer
                Künstler-Patenschaft an.
              </Trans>
            </StandardParagraph>
          </div>
          <div>
            <SecondaryHeading>
              <Trans>Förderer</Trans>
            </SecondaryHeading>
            <StandardParagraph>
              <Trans i18nKey="mitmachen.forderer_block">
                Förderer ermöglichen als private, gewerbliche oder
                institutionelle Spender mit einem einmaligen Beitrag oder
                wiederkehrenden Spenden die laufende Arbeit der JES
                Kulturstiftung. Gerne informieren wir Sie persönlich über die
                Möglichkeiten, als Förderer der JES-Kulturstiftung aufgenommen
                zu werden.
              </Trans>
            </StandardParagraph>
          </div>
          <div>
            <SecondaryHeading>
              <Trans>Zustiftung</Trans>
            </SecondaryHeading>
            <StandardParagraph>
              <Trans i18nKey="mitmachen.zustiftung_block">
                Bei einer Zustiftung werden die bereitgestellten Finanzmittel -
                anders als Spenden - nicht unmittelbar für Stiftungsaufgaben
                verwendet. Stattdessen wird das eingebrachte Kapital unter
                Kontrolle der staatlichen Stiftungsaufsicht Oberbayern sicher
                angelegt und die erwirtschafteten Erträge kommen der Stiftung
                langfristig zugute. Sprechen Sie uns bitte an, wenn Sie eine
                Zustiftung machen möchten.
              </Trans>
            </StandardParagraph>
          </div>
        </HalfCol>
        <HalfCol style={{ paddingLeft: "15px" }} noPaddingMobile>
          <div
            style={{
              background: "#efe9e4",
              marginBottom: "30px",
              padding: BLOCKS_SPACING,
            }}
          >
            <PrimaryHeading style={{ fontFamily: "Accord-Bold" }}>
              <Trans>WIR SUCHEN</Trans>
            </PrimaryHeading>
            <StandardParagraph>
              <Bold>
                <Trans>Kunstwerke</Trans>:{" "}
              </Bold>
              <Trans i18nKey="mitmachen.kunstwerke_block">
                Zur Erstellung von Werkverzeichnissen möchten wir in
                Privatbesitz befindliche Bilder, Zeichnungen, Fotos und
                Plastiken der Holzhauser Künstler dokumentieren. Bitte nehmen
                Sie mit uns Kontakt auf, wenn Sie Werke von Holzhauser Künstlern
                in Ihrem Besitz haben. Diskretion sichern wir Ihnen zu.
              </Trans>
            </StandardParagraph>
            <StandardParagraph>
              <Bold>
                <Trans>Holzhauser G’schicht’n</Trans>:{" "}
              </Bold>
              <Trans i18nKey="mitmachen.gschicht_block">
                Wir suchen Holzhauser G‘schicht’n in Zusammenhang mit den
                Künstlern, ihren Häusern oder ganz allgemein aus unserem Ort.
                Wir freuen uns sehr, wenn Sie etwas beitragen können. Bitte
                melden Sie sich bei uns.
              </Trans>
            </StandardParagraph>
          </div>
          <div
            style={{
              background: "#ded6d0",
              marginBottom: "30px",
              padding: BLOCKS_SPACING,
            }}
          >
            <PrimaryHeading style={{ fontFamily: "Accord-Bold" }}>
              <Trans>SPENDENKONTO</Trans>
            </PrimaryHeading>
            <StandardParagraph style={{ fontSize: "24px" }}>
              <Trans>JES Kulturstiftung</Trans>
              <br />
              <Trans>Sparkasse Landsberg/Diessen</Trans>
            </StandardParagraph>
            <StandardParagraph style={{ fontSize: "24px" }}>
              <Trans>IBAN:</Trans>
              <br /> <Iban bold inheritFontSize />
            </StandardParagraph>
            <StandardParagraph style={{ fontSize: "24px" }}>
              <Trans>Verwendungszweck</Trans>:
              <br />
              <Trans>Spende</Trans>
            </StandardParagraph>
          </div>
          <div>
            <Legend>
              <i>
                <Trans i18nKey="mitmachen.spendenkonto_legend">
                  Die JES Kulturstiftung ist als gemeinnützige Organisation
                  autorisiert, steuerlich wirksame Spendenquittungen
                  auszustellen.
                </Trans>
              </i>
            </Legend>
          </div>
        </HalfCol>
      </JesRow>
    </JesLayout>
  )
}

export default MitmachenPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
